import * as React from 'react';
import { Link } from 'gatsby';
import styles from './tabs.module.css';
import Tabs from 'components/tabs';
import { TechnologyGroup } from 'components/technology';
import list from './list.json';

const Routes = require('../../../components/routes').Routes;

interface Props {
  onChange: (idx: number) => void;
}

function WP({ onChange }: Props) {
  return (
    <Tabs list={list} onChange={onChange} btnClassName={styles.btn}>
      <li className={styles.item}>
        <div className={styles.column}>
          <h3 className={styles.title}>Problems we solve</h3>
          <p className={styles.text}>
            Driven by the latest innovative tech trends, we help agro companies digitalize the
            agricultural sector. Our farm management tools allow for streamlining the workflow,
            enhancing customer experience and making data-driven decisions.
          </p>
          <div className={styles.linkContainer}>
            <Link
              className={styles.link}
              to={Routes.INDUSTRIES_AGRITECH}
              title="Learn more about AGRITECH industry"
            >
              Learn more about AGRITECH industry
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'amazon']} />
        </div>
      </li>
      <li className={styles.item}>
        <div className={styles.column}>
          <h3 className={styles.title}>Problems we solve</h3>
          <p className={styles.text}>
            Based on our deep understanding of financial intricacies and challenges, we work side by
            side with fintech providers to create highly secure and scalable custom-designed
            solutions that drive profits and enhance the customer experience.
          </p>
          <div className={styles.linkContainer}>
            <Link
              className={styles.link}
              to={Routes.INDUSTRIES_FINTECH}
              aria-label="Learn more about FINTECH industry"
            >
              Learn more about FINTECH industry
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'amazon']} />
        </div>
      </li>
      <li className={styles.item}>
        <div className={styles.column}>
          <h3 className={styles.title}>Problems we solve</h3>
          <p className={styles.text}>
            We drive state-of-the-art technologies in the sharing economy sector to help
            ride-sharing companies deliver a fascinating user experience to both drivers and
            passengers.
          </p>
          <div className={styles.linkContainer}>
            <Link
              className={styles.link}
              to={Routes.INDUSTRIES_RIDESHARING}
              aria-label="Learn more about RIDESHARING industry"
            >
              Learn more about RIDESHARING industry
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'amazon']} />
        </div>
      </li>
      <li className={styles.item}>
        <div className={styles.column}>
          <h3 className={styles.title}>Problems we solve</h3>
          <p className={styles.text}>
            We empower Foodtech companies with custom feature-rich apps to help them promote their
            services, ensure data transparency and make online payments more manageable for all
            parties, thus enhancing the customer experience they deliver.
          </p>
          <div className={styles.linkContainer}>
            <Link
              className={styles.link}
              to={Routes.INDUSTRIES_FOODTECH}
              aria-label="Learn more about FOODTECH industry"
            >
              Learn more about FOODTECH industry
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'amazon']} />
        </div>
      </li>
      <li className={styles.item}>
        <div className={styles.column}>
          <h3 className={styles.title}>Problems we solve</h3>
          <p className={styles.text}>
            Backed by our cutting-edge solutions, event providers can streamline their business
            strategies and plans, effectively manage their projects and acquire full control over
            their budget.
          </p>
          <div className={styles.linkContainer}>
            <Link
              className={styles.link}
              to={Routes.INDUSTRIES_EVENTS_MANAGMENT}
              aria-label="Learn more about EVENTS MANAGMENT industry"
            >
              Learn more about EVENTS MANAGMENT industry
            </Link>
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'amazon']} />
        </div>
      </li>
    </Tabs>
  );
}

export default WP;
