const BLOG = '/blog/';
const CAREER = '/careers/';
const CASE_STUDIES = '/case-studies/';
const SERVICES = '/services/';
const INDUSTRIES = '/industries/';
const TECHNOLOGIES = '/technologies/';
const ABOUT_US = '/about-us/';
const COOKIES = '/privacy-policy/';
const PARTNERSHIP = '/partnership/';
const PRIVACY_POLICY = '/privacy-policy/';
const GET_IN_TOUCH = '/get-in-touch/';
const FITNESS_APP_API_INTEGRATIONS = '/fitness-app-api-integrations/';
const FITNESS_MOBILE_SOLUTIONS_A = '/fitness-mobile-solutions/';
const FITNESS_MOBILE_SOLUTIONS_B = '/fitness-apps-development-company/';
const FITNESS_CENTER_APP = '/gym-software-development-company/';
const FITNESS_APP_CALCULATOR = '/fitness-app-development-cost-calculator/';
const CHAT_GPT = '/chatgpt-for-business-owners/';
const SWG_OFFER = '/swg-offer/';
const DEVELOPERS_CV = '/saas-developers/';

const Routes = {
  BLOG,
  BLOG_ALL: BLOG + 'all',
  BLOG_NEWS: BLOG + 'news',
  BLOG_RND: BLOG + 'research-development',
  BLOG_INTERVIEW: BLOG + 'interview',
  BLOG_CEO_INSIGHTS: BLOG + 'ceo-insights',
  BLOG_SEARCH: BLOG + 'search',
  BLOG_TAGS: BLOG + 'tags',

  CAREER,
  CASE_STUDIES,
  CASE_STUDIES_EVERYPIG_LOGISTIC: CASE_STUDIES + 'everypig-logistics-platform/',
  CASE_STUDIES_HEYPRACTICE: CASE_STUDIES + 'heypractice-ai-based-learning-platform/',
  CASE_STUDIES_EVERYPIG: CASE_STUDIES + 'everypig-pork-production-software/',
  CASE_STUDIES_GOKADA: CASE_STUDIES + 'gokada-ridesharing-app/',
  CASE_STUDIES_KOROBOX: CASE_STUDIES + 'korobox-snacks-delivery-app/',
  CASE_STUDIES_POWERCHAIN: CASE_STUDIES + 'supplychain-management-software/',
  CASE_STUDIES_BACKBONE: CASE_STUDIES + 'backbone-events-management-software/',
  CASE_STUDIES_BROCODERS: CASE_STUDIES + 'brocoders-corporate-website-development/',
  CASE_STUDIES_LADDEROUT: CASE_STUDIES + 'ladderout-mental-health-software/',
  CASE_STUDIES_DOKKA: CASE_STUDIES + 'dokka-automated-accounting-software/',
  CASE_STUDIES_ANUEL: CASE_STUDIES + 'fine-ecommerce-platform/',
  CASE_STUDIES_APPETINI: CASE_STUDIES + 'appetini-lunch-delivery-solution/',
  CASE_STUDIES_CONDOGENIE: CASE_STUDIES + 'condogenie-condominium-management-platform/',
  CASE_STUDIES_BEYOND_GREEN: CASE_STUDIES + 'beyond-green-meal-order-managment-service/',
  CASE_STUDIES_SKILENT: CASE_STUDIES + 'skilent-recruiting-software/',
  CASE_STUDIES_MYCURRENCY: CASE_STUDIES + 'mycurrency-loyalty-points-exchange-platform/',
  CASE_STUDIES_XELLARDOOR: CASE_STUDIES + 'xellardoor-online-fashion-marketplace/',
  CASE_STUDIES_GERIANA: CASE_STUDIES + 'geriana-peer-to-peer-caregiving-platform/',
  CASE_STUDIES_I_FIT: CASE_STUDIES + 'api-integrations-for-interactive-workout-application/',
  CASE_STUDIES_GYM_CLUB: CASE_STUDIES + 'gym-management-software/',
  CASE_STUDIES_ADACT: CASE_STUDIES + 'adact-marketing-gamification-platform/',
  CASE_STUDIES_HYPEBOARD: CASE_STUDIES + 'hypeboard-instagram-analytics-tool/',
  CASE_STUDIES_CONVERTHERO: CASE_STUDIES + 'converthero-exit-intent-popup-software/',
  CASE_STUDIES_HOA_MANAGMENT: CASE_STUDIES + 'hoa-management-application/',
  CASE_STUDIES_TRADERS_ALLOY: CASE_STUDIES + 'ci-cd-for-a-fintech-startup-traders-alloy/',
  CASE_STUDIES_WAGEPOINT: CASE_STUDIES + 'team-augmentation-for-payroll-software-wagepoint/',
  CASE_STUDIES_AREABUTLER: CASE_STUDIES + 'empower-proptech-startup-areabutler-with-a-remote-team/',
  CASE_STUDIES_REVENUE_BOOSTERS: CASE_STUDIES + 'revenue-boosters-route-management-saas/',

  SERVICES_MVP_DEVELOPMENT: SERVICES + 'mvp-development/',
  SERVICES_PRODUCT_DEVELOPMENT: SERVICES + 'product-development/',
  SERVICES_DEDICATED_TEAM: SERVICES + 'dedicated-team/',
  SERVICES_DIGITAL_TRANSFORMATION: SERVICES + 'digital-transformation/',
  SERVICES_MOBILE_APP_DEVELOPMENT: SERVICES + 'mobile-application-development/',
  SERVICES_WEB_APP_DEVELOPMENT: SERVICES + 'web-application-development/',
  SERVICES_DEVOPS: SERVICES + 'devops-development-services/',
  SERVICES_TEAM_AUGMENTATION: SERVICES + 'team-augmentation-services/',
  SERVICES_SAAS_DEVELOPMENT: SERVICES + 'saas-development/',
  SERVICES_QA: SERVICES + 'software-testing-services/',
  SERVICES_AI: SERVICES + 'ai-development-and-integration-services/',

  INDUSTRIES_AGRITECH: INDUSTRIES + 'agritech-software-development/',
  INDUSTRIES_FINTECH: INDUSTRIES + 'fintech-software-development/',
  INDUSTRIES_EVENTS_MANAGMENT: INDUSTRIES + 'events-management-software-development/',
  INDUSTRIES_RIDESHARING: INDUSTRIES + 'ridesharing-app-development/',
  INDUSTRIES_FOODTECH: INDUSTRIES + 'foodtech-software-development/',
  INDUSTRIES_MARTECH: INDUSTRIES + 'martech-development-services/',
  INDUSTRIES_EDTECH: INDUSTRIES + 'edtech-development-services/',
  INDUSTRIES_PROPTECH: INDUSTRIES + 'proptech-software-development-services/',

  TECHNOLOGIES_NODEJS: TECHNOLOGIES + 'nodejs-development-services/',
  TECHNOLOGIES_REACTJS: TECHNOLOGIES + 'reactjs-development-services/',
  TECHNOLOGIES_REACT_NATIVE_JS: TECHNOLOGIES + 'react-native-development-services/',
  TECHNOLOGIES_GATSBY: TECHNOLOGIES + 'gatsbyjs-development-services/',
  TECHNOLOGIES_BLOCKCHAIN: TECHNOLOGIES + 'blockchain-development-services/',

  ABOUT_US,
  COOKIES,
  PARTNERSHIP,
  GET_IN_TOUCH,
  FITNESS_CENTER_APP,
  FITNESS_APP_API_INTEGRATIONS,
  FITNESS_MOBILE_SOLUTIONS_A,
  FITNESS_MOBILE_SOLUTIONS_B,
  FITNESS_APP_CALCULATOR,
  SWG_OFFER,
  CHAT_GPT,
  PRIVACY_POLICY,
  DEVELOPERS_CV,
};

module.exports.Routes = Routes;
